export const AcceptedImageFormat = [
    'image/jpg',
    'image/png',
    'image/jpeg',
    'image/tiff',
    'image/svg+xml',
    'image/gif',
  ];

export const DateTimeFormat = "LLL"
export const DateFormat = "ll"