import { IAppRoutes } from '../interfaces/AppRoutes';

export const AppRoutes: IAppRoutes = {
  MAIN: "/",
  LOGIN: "/login",
  HOME: "/dashboard",
  MY_PROFILE: "/my-profile",
  USER: "/user",
  ADD_USER: "/user/add",
  EDIT_USER: "/user/edit/:id",
  EMAILTEMPLATE: "/email-template",
  ADD_TEMPLATE: "/email-template/add",
  EDIT_TEMPLATE: "/email-template/edit/:id",
  //Home Page
  ADD_HOME_PAGE: "/homepage",
  ADD_FAQ: "/faq/add",
  FAQ: "/faq",
  ENTERPRISE: "/enterprise",
  BLOG_CATEGORY: "/blog-category",
  ADD_BLOG_CATEGORY: "/blog-category/add",
  EDIT_BLOG_CATEGORY: "/blog-category/edit/:id",
  BLOG: "/blog",
  ADD_BLOG: "/blog/add",
  EDIT_BLOG: "/blog/edit/:id",
  // COurse Managment
  COURSE: "/course",
  ADD_COURSE: "/course/add-course",
  EDIT_COURSE: "/course/edit-course/:id",
  MODULE: "/module",
  ADD_MODULE: "/module/add",
  EDIT_MODULE: "/module/edit/:id",
  PRESS_RELEASE_CATEGORY: "/press-release-category",
  ADD_PRESS_RELEASE_CATEGORY: "/press-release-category/add",
  EDIT_PRESS_RELEASE_CATEGORY: "/press-release-category/edit/:id",
  PRESS_RELEASE: "/press-release",
  ADD_PRESS_RELEASE: "/press-release/add",
  EDIT_PRESS_RELEASE: "/press-release/edit/:id",
  PROMO_CODE: "/promo-code",
  ADD_PROMO_CODE: "/promo-code/add",
  EDIT_PROMO_CODE: "/promo-code/edit/:id",
  TRANSACTION: "/transaction",
  REPORT_USER: "/report/user",
  REPORT_SALE: "/report/sale",
  CERTIFICATE: "/certificate",
  SETTING: "/setting",
  CATEGORY: "/category",
  ADD_CATEGORY: "/category/add",
  EDIT_CATEGORY: "/category/edit/:id",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_CONDITIONS: "/terms-conditions",
  REFUND_POLICY: "/refund-policy",
  DO_NOT_SELL: "/do-not-sell",
  UNIVERSITY: "/university",
  ADD_UNIVERSITY: "/university/add-university",
  EDIT_UNIVERSITY: "/university/edit/:id",

  // ADD_CORPORATE: "/corporate/add-corporate",
  CORPORATE: "/corporate",
  ADD_CORPORATE: "/corporate/add-corporate",
  EDIT_CORPORATE: "/corporate/edit-corporate",
  ///Add Labs section
  LAB: "/lab",
  ADD_LAB: "/lab/add-lab",
  EDIT_LAB: "/lab/edit-lab",
  // For Sub Admin
  SUB_ADMIN: "/sub-admin",
  ADD_SUB_ADMIN: "/sub-admin/add-sub-admin",
  EDIT_SUB_ADMIN: "/sub-admin/edit-sub-admin",
  // For Frontend Template
  POLICY_PAGES: "/policy-pages",
  EDIT_POLICY_PAGES: "/policy-pages/edit-policy-pages",
  // For Our Team
  OUR_TEAM: "/our-team",
  ADD_TEAM: "/our-team/add-team",
  EDIT_TEAM: "/our-team/edit-team",
  // For Front End Pages
  FRONTEND_PAGES: "/front-pages",
  EDIT_ABOUT_PAGES: "/front-pages/edit-about-pages",
  EDIT_ENTERPRISE_PAGES: "/front-pages/edit-enterprise-pages",
  EDIT_LABS_PAGES: "/front-pages/edit-labs-pages",
  EDIT_CERTIFICATION_PAGES: "/front-pages/edit-certification-pages",
  EDIT_UNIVERSITY_PAGES: "/front-pages/edit-university-pages",
  EDIT_COURSES_PAGES: "/front-pages/edit-courses-pages",
  EDIT_MARKETING_PAGES: "/front-pages/edit-marketing-pages",
  EDIT_HOME_PAGES: "/front-pages/edit-home-pages",
  EDIT_FOOTER_PAGES: "/front-pages/edit-footer-pages",
};