import Axios from 'axios';
import { IApiRoutes } from '../interfaces';

export const ApiRoutes: IApiRoutes = {
  ADMIN_LOGIN: {
    service: "/auth",
    url: "/login",
    method: "POST",
    authenticate: false,
  },
  ADMIN_PROFILE: {
    service: "/auth",
    url: "/details",
    method: "GET",
    authenticate: true,
  },
  ADMIN_PROFILE_UPDATE: {
    service: "/auth",
    url: "/update",
    method: "PUT",
    authenticate: true,
  },
  ADMIN_CHANGE_PASSWORD: {
    service: "/auth",
    url: "/change-password",
    method: "PUT",
    authenticate: true,
  },
  // Proxy Login
  ADMIN_PROXY_LOGIN: {
    service: "/auth",
    url: "/admin-proxy-login",
    method: "POST",
    authenticate: true,
  },

  // User Module related APIs
  GET_USER: {
    service: "",
    url: "/user",
    method: "GET",
    authenticate: true,
  },
  ADD_USER: {
    service: "/user",
    url: "",
    method: "POST",
    authenticate: true,
  },
  UPDATE_USER: {
    service: "/user",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_USER: {
    service: "/user",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  VIEW_USER: {
    service: "/user",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_USER_STATUS: {
    service: "/user",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  USER_BULK_ACTION: {
    service: "/user",
    url: "/bulk-action",
    method: "POST",
    authenticate: true,
  },
  // Coure Managment
  GET_COURSE_TOPICS: {
    service: "/course-topic",
    url: "",
    method: "GET",
    authenticate: true,
  },
  GET_COURSE: {
    service: "/course",
    url: "",
    method: "GET",
    authenticate: true,
  },
  ADD_COURSE: {
    service: "/course",
    url: "",
    method: "POST",
    authenticate: true,
  },
  VIEW_COURSE: {
    service: "/course",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_COURSE: {
    service: "/course",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_COURSE: {
    service: "/course",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  UPDATE_COURSE_STATUS: {
    service: "/course",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  // Module
  GET_MODULE: {
    service: "/module",
    url: "",
    method: "GET",
    authenticate: true,
  },
  ADD_MODULE: {
    service: "/module",
    url: "",
    method: "POST",
    authenticate: true,
  },
  VIEW_MODULE: {
    service: "/module",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_MODULE: {
    service: "/module",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_MODULE_STATUS: {
    service: "/module",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_MODULE: {
    service: "/module",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  // Email Template
  ADD_TEMPLATE: {
    service: "/email-templates",
    url: "/add",
    method: "POST",
    authenticate: true,
  },
  UPDATE_TEMPLATE: {
    service: "/email-templates",
    url: "/update",
    method: "put",
    authenticate: true,
  },
  GET_TEMPLATE: {
    service: "/email-templates",
    url: "/get",
    method: "get",
    authenticate: true,
  },
  VIEW_TEMPLATE: {
    service: "/email-templates",
    url: "/view",
    method: "get",
    authenticate: true,
  },

  EXPORT_REPORT: {
    service: "/organization",
    url: "/export-report",
    method: "GET",
    authenticate: true,
  },

  // Home Page
  ADD_HOME_PAGE: {
    service: "/homepage",
    url: "/add",
    method: "POST",
    authenticate: true,
  },
  VIEW_HOME_PAGE: {
    service: "/homepage",
    url: "/view-homepage",
    method: "GET",
    authenticate: true,
  },
  UPDATE_HOME_PAGE: {
    service: "/homepage",
    url: "/update-homepage",
    method: "PUT",
    authenticate: true,
  },
  // Faq Routes
  ADD_FAQ: {
    service: "/faq",
    url: "/add",
    method: "POST",
    authenticate: true,
  },
  GET_FAQ: {
    service: "/faq",
    url: "/get",
    method: "GET",
    authenticate: true,
  },
  UPDATE_FAQ_STATUS: {
    service: "/faq",
    url: "/update-status",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_FAQ: {
    service: "/faq",
    url: "/uodate",
    method: "PUT",
    authenticate: true,
  },
  GET_ENTERPRISE: {
    service: "/enterprise",
    url: "",
    method: "GET",
    authenticate: true,
  },
  UPDATE_BLOG_CATEGORY_STATUS: {
    service: "/blog-category",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_BLOG_CATEGORY: {
    service: "/blog-category",
    url: "",
    method: "GET",
    authenticate: true,
  },
  ADD_BLOG_CATEGORY: {
    service: "/blog-category",
    url: "",
    method: "POST",
    authenticate: true,
  },
  UPDATE_BLOG_CATEGORY: {
    service: "/blog-category",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  VIEW_BLOG_CATEGORY: {
    service: "/blog-category",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  DELETE_BLOG_CATEGORY: {
    service: "/blog-category",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  GET_BLOG: {
    service: "/blog",
    url: "",
    method: "GET",
    authenticate: true,
  },
  ADD_BLOG: {
    service: "/blog",
    url: "",
    method: "POST",
    authenticate: true,
  },
  UPDATE_BLOG: {
    service: "/blog",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  VIEW_BLOG: {
    service: "/blog",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_BLOG_STATUS: {
    service: "/blog",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_BLOG: {
    service: "/blog",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  UPDATE_PRESS_RELEASE_CATEGORY_STATUS: {
    service: "/press-release-category",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_PRESS_RELEASE_CATEGORY: {
    service: "/press-release-category",
    url: "",
    method: "GET",
    authenticate: true,
  },
  ADD_PRESS_RELEASE_CATEGORY: {
    service: "/press-release-category",
    url: "",
    method: "POST",
    authenticate: true,
  },
  UPDATE_PRESS_RELEASE_CATEGORY: {
    service: "/press-release-category",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  VIEW_PRESS_RELEASE_CATEGORY: {
    service: "/press-release-category",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  DELETE_PRESS_RELEASE_CATEGORY: {
    service: "/press-release-category",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  GET_PRESS_RELEASE: {
    service: "/press-release",
    url: "",
    method: "GET",
    authenticate: true,
  },
  ADD_PRESS_RELEASE: {
    service: "/press-release",
    url: "",
    method: "POST",
    authenticate: true,
  },
  UPDATE_PRESS_RELEASE: {
    service: "/press-release",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  VIEW_PRESS_RELEASE: {
    service: "/press-release",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_PRESS_RELEASE_STATUS: {
    service: "/press-release",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_PRESS_RELEASE: {
    service: "/press-release",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  GET_PROMO_CODE: {
    service: "/promo-code",
    url: "",
    method: "GET",
    authenticate: true,
  },
  ADD_PROMO_CODE: {
    service: "/promo-code",
    url: "",
    method: "POST",
    authenticate: true,
  },
  UPDATE_PROMO_CODE: {
    service: "/promo-code",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  VIEW_PROMO_CODE: {
    service: "/promo-code",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_PROMO_CODE_STATUS: {
    service: "/promo-code",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_PROMO_CODE: {
    service: "/promo-code",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  GET_TRANSACTION: {
    service: "/transaction",
    url: "",
    method: "GET",
    authenticate: true,
  },
  GET_USER_REPORT: {
    service: "/report",
    url: "/user",
    method: "GET",
    authenticate: true,
  },
  GET_SALE_REPORT: {
    service: "/report",
    url: "/sales",
    method: "GET",
    authenticate: true,
  },
  GET_DASHBOARD: {
    service: "/dashboard",
    url: "/courseAndUserCount",
    method: "GET",
    authenticate: true,
  },
  GET_UNIVERSITY: {
    service: "/user",
    url: "/universities",
    method: "GET",
    authenticate: true,
  },
  GET_CORPORATION: {
    service: "/user",
    url: "/corporation",
    method: "GET",
    authenticate: true,
  },
  GET_CERTIFICATE: {
    service: "/certificate-request",
    url: "",
    method: "GET",
    authenticate: true,
  },
  UPDATE_CERTIFICATE_STATUS: {
    service: "/certificate-request",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  VIEW_SETTING: {
    service: "/setting",
    url: "",
    method: "GET",
    authenticate: true,
  },
  UPDATE_SETTING: {
    service: "/setting",
    url: "",
    method: "PUT",
    authenticate: true,
  },
  GET_CATEGORY: {
    service: "/course-topic",
    url: "",
    method: "GET",
    authenticate: true,
  },
  ADD_CATEGORY: {
    service: "/course-topic",
    url: "",
    method: "POST",
    authenticate: true,
  },
  VIEW_CATEGORY: {
    service: "/course-topic",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  UPDATE_CATEGORY: {
    service: "/course-topic",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  UPDATE_CATEGORY_STATUS: {
    service: "/course-topic",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_CATEGORY: {
    service: "/course-topic",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  CATEGORY_BULK_ACTION: {
    service: "/course-topic",
    url: "/bulk-action",
    method: "POST",
    authenticate: true,
  },
  BLOG_BULK_ACTION: {
    service: "/blog",
    url: "/bulk-action",
    method: "PUT",
    authenticate: true,
  },
  PRESS_RELEASE_BULK_ACTION: {
    service: "/press-release",
    url: "/bulk-action",
    method: "PUT",
    authenticate: true,
  },
  PROMOCODE_BULK_ACTION: {
    service: "/promo-code",
    url: "/bulk-action",
    method: "PUT",
    authenticate: true,
  },
  COURSE_BULK_ACTION: {
    service: "/course",
    url: "/bulk-action",
    method: "PUT",
    authenticate: true,
  },
  MODULE_BULK_ACTION: {
    service: "/module",
    url: "/bulk-action",
    method: "PUT",
    authenticate: true,
  },
  GET_TRANSATION_REPORTS: {
    service: "/transaction",
    url: "/transaction-csv",
    method: "GET",
    authenticate: true,
  },
  GET_USER_REPORTS: {
    service: "/user",
    url: "/user-csv",
    method: "GET",
    authenticate: true,
  },
  TALENT_LMS_API: {
    service: "/talent-lms",
    url: "/add-course",
    method: "GET",
    authenticate: true,
  },
  ADD_UNIVERSITY_NAME: {
    service: "/university",
    url: "",
    method: "POST",
    authenticate: true,
  },
  GET_UNIVERSITY_NAME: {
    service: "/university",
    url: "",
    method: "GET",
    authenticate: true,
  },
  GET_CORPORATE: {
    service: "/corporate",
    url: "",
    method: "GET",
    authenticate: true,
  },
  ADD_CORPORATE: {
    service: "/corporate",
    url: "",
    method: "POST",
    authenticate: true,
  },
  UPDATE_CORPORATE_STATUS: {
    service: "/corporate",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_CORPORATE: {
    service: "/corporate",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  VIEW_CORPORATE: {
    service: "/corporate",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  EDIT_CORPORATE: {
    service: "/corporate",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  EDIT_UNIVERSITY: {
    service: "/university",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_UNIVERSITY: {
    service: "/university",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  UPDATE_UNIVERSITY_STATUS: {
    service: "/university",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  VIEW_UNIVERSITY: {
    service: "/university",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  GET_SUB_ADMIN: {
    service: "/admin",
    url: "",
    method: "GET",
    authenticate: true,
  },
  Add_SUB_ADMIN: {
    service: "/admin",
    url: "",
    method: "POST",
    authenticate: true,
  },
  VIEW_SUB_ADMIN: {
    service: "/admin",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  EDIT_SUB_ADMIN: {
    service: "/admin",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_SUB_ADMIN: {
    service: "/admin",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  STATUS_SUB_ADMIN: {
    service: "/admin",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  REPORT_SUB_ADMIN: {
    service: "/admin",
    url: "/admin-csv",
    method: "GET",
    authenticate: true,
  },
  BULK_SUB_ADMIN: {
    service: "/admin",
    url: "/bulk-action",
    method: "POST",
    authenticate: true,
  },
  GET_FRONTEND_TEMPLATE: {
    service: "/cms",
    url: "",
    method: "GET",
    authenticate: true,
  },
  VIEW_FRONTEND_TEMPLATE: {
    service: "/cms",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  EDIT_FRONTEND_TEMPLATE: {
    service: "/cms",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  GET_OUR_TEAM: {
    service: "/team",
    url: "",
    method: "GET",
    authenticate: true,
  },
  ADD_OUR_TEAM: {
    service: "/team",
    url: "",
    method: "POST",
    authenticate: true,
  },
  VIEW_OUR_TEAM: {
    service: "/team",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  EDIT_OUR_TEAM: {
    service: "/team",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  ORDER_OUR_TEAM:
  {
    service: "/team",
    url: "/update-order",
    method: "PUT",
    authenticate: true,
  },
  DELETE_OUR_TEAM: {
    service: "/team",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  STATUS_OUR_TEAM: {
    service: "/team",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  BULK_OUR_TEAM: {
    service: "/team",
    url: "/bulk-action",
    method: "POST",
    authenticate: true,
  },
  GET_FRONTEND_PAGES: {
    service: "/market-content",
    url: "",
    method: "GET",
    authenticate: true,
  },
  VIEW_FRONTEND_PAGES: {
    service: "/market-content",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  EDIT_FRONTEND_PAGES: {
    service: "/market-content",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  ///For labs crud starts here
  ADD_LAB: {
    service: "/lab",
    url: "",
    method: "POST",
    authenticate: true,
  },
  GET_LAB: {
    service: "/lab",
    url: "",
    method: "GET",
    authenticate: true,
  },
  STATUS_LAB: {
    service: "/lab",
    url: "/update-status/:id",
    method: "PUT",
    authenticate: true,
  },
  DELETE_LAB: {
    service: "/lab",
    url: "/:id",
    method: "DELETE",
    authenticate: true,
  },
  VIEW_LAB: {
    service: "/lab",
    url: "/:id",
    method: "GET",
    authenticate: true,
  },
  EDIT_LAB: {
    service: "/lab",
    url: "/:id",
    method: "PUT",
    authenticate: true,
  },
  BULK_LAB: {
    service: "/lab",
    url: "/bulk-action",
    method: "PUT",
    authenticate: true,
  },
};
