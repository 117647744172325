export * from './Login';
export * from './Profile';
export * from './ChangePassword';
export * from './User';
export * from './EmailTemplate';
export * from './CMS';
export * from './Faq';
export * from './Enterprise';
export * from './BlogCategory';
export * from './Blog';
export * from './Course';
export * from './Module';
export * from './PressReleaseCategory';
export * from './PressRelease';
export * from './PromoCode';
export * from './Transaction';
export * from './Report';
export * from './Dashboard';
export * from './Certificate';
export * from './Setting';
export * from "./Category";
export * from "./UniversityDetail";
export * from "./Corporate";
export * from "./OurTeam";
export * from "./SubAdminState";
export * from "./FrontendTemplateState";
export * from "./FrontendPagesState";
export * from "./Lab";